<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p>
          Se Gestiona todo tipo de publicaciones, enlaces, texto html o
          archivos.
        </p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="openNew"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} publicaciones"
          responsiveLayout="scroll"
          :globalFilterFields="['titulo', 'resumen']"
        >
          <template #header>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
            >
              <h5 class="p-m-0">Administrar Publicaciones</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="id" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="fecha" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{ slotProps.data.fechaInicio }}
            </template>
          </Column>

          <Column field="titulo" header="Título" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Título</span>
              {{ formatCurrency(slotProps.data.titulo) }}
            </template>
          </Column>
          <Column field="resumen" header="Resumen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Resumen</span>
              {{ formatCurrency(slotProps.data.resumen) }}
            </template>
          </Column>
          <Column field="rating" header="Rating" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Rating</span>
              <Rating
                :modelValue="slotProps.data.rating"
                :readonly="true"
                :cancel="false"
              />
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '550px' }"
          header="Detalles de la Publicación"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="tipoStatus" class="p-mb-3">Tipo</label>
            <Dropdown
              id="id"
              v-model="product.tipo"
              :options="tipos"
              optionLabel="nombre"
              placeholder="Seleccione el tipo (publicación)"
              autofocus
              :class="{
                'p-invalid': submitted && !product.tipo.id,
              }"
            >
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !product.tipo.id"
              >Seleccione un tipo.</small
            >
          </div>
          <div class="p-field">
            <label for="name">Título</label>
            <InputText
              id="name"
              v-model.trim="product.titulo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.titulo }"
            />
            <small class="p-invalid" v-if="submitted && !product.titulo"
              >Título es requerido.</small
            >
          </div>
          <div class="p-field">
            <label for="description">Resumen</label>
            <Textarea
              id="description"
              v-model="product.resumen"
              required="true"
              rows="3"
              cols="20"
            />
          </div>
          <div class="p-field">
            <label>Fecha del inicio </label>
            <Calendar
              :showIcon="true"
              :showButtonBar="true"
              v-model="product.fechaInicio"
              dateFormat="dd/mm/yy"
              :class="'calendar-body'"
            ></Calendar>
          </div>

          <div v-if="product.tipo.id === 1">
            <div class="p-field">
              <label class="p-mb-3"></label>
              <FileUpload
                mode="basic"
                :customUpload="true"
                @uploader="myUploader"
                :fileLimit="1"
                required="true"
                :auto="true"
                :class="{
                  'p-invalid': submitted && !filetitulo && product.tipo.id == 1,
                }"
              />
              <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
              <small
                class="p-invalid"
                v-if="submitted && !filetitulo && product.tipo.id == 1"
              >
                Archivo es requerido.</small
              >
            </div>
          </div>
          <div v-else-if="product.tipo.id === 3">
            <div class="p-field">
              <label for="enlace">Enlace</label>
              <InputText
                id="enlace"
                v-model.trim="product.enlace"
                required="true"
                autofocus
                :class="{
                  'p-invalid':
                    submitted && !product.enlace && product.tipo.id == 3,
                }"
              />
              <small
                class="p-invalid"
                v-if="submitted && !product.enlace && product.tipo.id == 3"
              >
                enlace es requerido.</small
              >
            </div>
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Está seguro de querer borrar la publicación:
              <b>{{ product.titulo }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      tipos: [
        { id: 2, nombre: "TEXTO HTML", estado: true },
        { id: 1, nombre: "ARCHIVO", estado: true },
        { id: 3, nombre: "ENLACE", estado: true },
      ],
      products: null,
      filetitulo: "",
      filetype: "",
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      etiquetas: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        titulo: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      submitted: false,
      action: "",
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
  },
  mounted() {
    this.product = this.model;
    this.listarData();

    (this.$primevue.config.locale.monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]),
      (this.$primevue.config.locale.dayNamesMin = [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa",
      ]);
    this.$primevue.config.locale.clear = "Borrar";
    this.$primevue.config.locale.today = "Hoy";
    this.$primevue.config.locale.choose = "Elegir";
    this.$primevue.config.locale.upload = "Subir";
    this.$primevue.config.locale.cancel = "Cancelar";
  },
  methods: {
    myUploader(file) {
      /// console.info(file.files[0]);

      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.tipoArchivo = file.files[0].type;

              this.product.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTítulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },

    listarData(e) {
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/PUBLICACION_LIST", e === undefined ? true : e)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    formatCurrency(value) {
      if (value.length >= 80) {
        return value.substring(0, 80) + "...";
      } else {
        return value;
      }
    },
    openNew() {
      this.action = "gsdialogo/PUBLICACION_SAVE";
      this.product = {
        id: 0,
        fechaInicio: new Date(),
        titulo: "",
        resumen: "",
        enlace: "",
        ruta: "",
        anulado: false,
        tipoArchivo: "",
        extension: "",
        tipo: { id: 2, nombre: "TEXTO HTML", estado: true },
      };
      this.filetitulo = "";
      this.filetype = "";
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    saveProduct() {
      this.submitted = true;
      let modelo = this.product;
      let fecha = new Date(modelo.fechaInicio);
      modelo.fechaInicio =
        fecha.getDate() +
        "/" +
        (fecha.getMonth() + 1) +
        "/" +
        fecha.getFullYear();
      if (this.product.titulo.trim()) {
        this.isBusy = true;

        this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.isBusy = false;
                this.$swal.close();
                this.listarData();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Publicación Actualizada id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                if (
                  error.response.status == 401 ||
                  error.response.status == 500
                ) {
                  this.$swal(
                    error.response.data.errorMessage,
                    "Ud. no tiene permitido esta función",
                    "warning"
                  );
                }
              });
          },
        }).then((result) => {
          console.log(result);
        });

        this.productDialog = false;
      }
    },
    editProduct(product) {
      this.action = "gsdialogo/PUBLICACION_UPDATE";
      this.product = { ...product };
      this.product.ruta = "";
      this.product.fechaInicio = new Date(
        parseInt(this.product.fechaInicio.substring(6, 10)),
        parseInt(this.product.fechaInicio.substring(3, 5)) - 1,
        parseInt(this.product.fechaInicio.substring(0, 2))
      );
      this.filetitulo = "archivo" + this.product.extension;
      this.filetype = "[" + this.product.tipoArchivo + "]";
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      let id = this.product.id;
      this.deleteProductDialog = false;
      this.product = {};

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/PUBLICACION_DELETE", id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Publicación borrada id: " + res.id,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
  },
  computed: { ...mapGetters({ model: "gsdialogo/getModelPubl" }) },
};
</script>
<style scoped lang="scss">
.calendar-body {
  height: 50px;
}
</style>
